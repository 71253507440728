<template>
  <div class="mpos">
    
    <!-- Main order list -->
    <v-lazy v-for="order in orders" :key="order.id" :options="{ threshold: .5 }"
      min-height="96px">
      <OrderCard ref="order-cards"
        :id="`order-${order.id}`"
        :order="order" @on-expanded="onOrderExpanded">
      </OrderCard>
    </v-lazy>

    <!-- Empty list hint -->
    <div class="pt-8 d-flex justify-center" v-if="!orders.length && searchString.length == 0">
      <v-progress-circular indeterminate color="purple" v-if="!isSyncDone"></v-progress-circular>
      <span class="headline font-weight-black grey--text" v-else>
        目前沒有 {{ filterType | toFilterLiteral }} 訂單
      </span>
    </div>

    <!-- List tail (shop name) -->
    <div class="my-4 subtitle text-center font-weight-black grey--text">
      <v-icon>mdi-store</v-icon>&nbsp;&nbsp;{{ shopName }}
    </div>

    <!-- Seach mode hint -->
    <div class="mt-4 text-center font-weight-bold grey--text" v-if="searchString.length">
      <span>搜尋：{{ searchString | padPhoneNumber }}</span>
      <v-btn block tile outlined small color="grey" @click="turnOffSearch">
        <v-icon left>mdi-magnify-close</v-icon> 點此離開搜尋模式
      </v-btn>
    </div>

    <!-- Bottom navigation (tabs) -->
    <v-bottom-navigation fixed dark :color="themeColor"
      v-model="filterType" @change="onFilterTypeChanged">
      
      <v-btn value="pending">
        <v-badge color="pink" dot :value="false">
          <span>待處理</span>
        </v-badge>
        <v-icon>mdi-clock-outline</v-icon>
      </v-btn>

      <v-btn value="done">
        <span>已處理</span>
        <v-icon>mdi-check</v-icon>
      </v-btn>

      <v-btn value="future">
        <span>預約</span>
        <v-icon>mdi-calendar</v-icon>
      </v-btn>

      <v-btn value="all">
        <span>全部</span>
        <v-icon>mdi-all-inclusive</v-icon>
      </v-btn>
    </v-bottom-navigation>


    <!-- Action Menu -->
    <PosActionMenu
      @search="search"
      :signout="signout">
    </PosActionMenu>

    <!-- Overlay - force you to tap first at entrance! -->
    <v-overlay :value="overlay.entry.show" :opacity="0.46">
      <v-btn x-large depressed rounded :color="themeColor" @click="overlay.entry.show = false">
        <v-icon large>mdi-gesture-tap-button</v-icon>
        <span class="ml-2 headline">開始使用</span>
      </v-btn>
    </v-overlay>

    <!-- Overlay - force you to notice the unresolved new orders -->
    <v-overlay :value="overlay.inactivity.show" :opacity="0.75">
      <v-btn x-large depressed rounded color="pink darken-3" @click="overlay.inactivity.show = false">
        <v-icon large>mdi-clock-alert</v-icon>
        <span class="ml-2 headline">尚有新進單未接／取消</span>
      </v-btn>
    </v-overlay>

    <!-- Alert snackbar - new incoming orders (manual mode only) -->
    <v-snackbar top rounded="pill" color="purple darken-2" :timeout="-1"
      v-model="snackbar.new.show">
      您有 {{ newOrdersAlert.newOrdersCount }} 筆新進單，請及時處理～
      <template v-slot:action="{ attrs }">
        <v-btn dark text v-bind="attrs" @click="onNewOrderAlertDismissed">Close</v-btn>
      </template>
    </v-snackbar>

  </div>
</template>

<script>
import { fauth, firestore, FirestoreDataEvent } from '../firebase';
import SoundManager from '../sound-manager';

import OrderCard from '../components/OrderCard';
import PosActionMenu from '../components/PosActionMenu';

import moment from 'moment';

export default {
  name: 'Mpos',
  components: {
    OrderCard,
    PosActionMenu
  },
  
  mounted() {
    this.isSyncDone = false;

    // Activate the reminder loop.
    this.reminderLoopTimeout = setTimeout(() => {//WARNING: Delay a few second to ensure orders are loaded.
      this.reminderLoop();
      this.inactivityTimer = 0;
      this.reminderLoopInterval = setInterval(this.reminderLoop, 60000);

      document.addEventListener('touchend', this.inactivityBreaker, false);
      document.addEventListener('mouseup', this.inactivityBreaker, false);

      // Unblock new-order alert snackbar.
      this.newOrdersAlert.on = true;
    }, 10000);

    // Listen to Firestore sync completion event.
    FirestoreDataEvent.$on('orders-sync-done', () => {
      this.isSyncDone = true;
    });

    // Listen to order-added (incoming) events.
    FirestoreDataEvent.$on('order-added', data => {
      console.warn(`on order-added`);
      if (this.newOrdersAlert.on) {
        this.newOrdersAlert.newOrdersCount++;
        this.snackbar.new.show = true;
      }
    });

    // // Listen to order-modified events.
    // FirestoreDataEvent.$on('order-modified', data => {
    //   //FIXME: Nothing to do here?
    // });
  },

  methods: {

    onOrderExpanded(_orderId) {
      //FIXME: Collapse any previously expanded order-card.
      // this.$refs['order-cards'].forEach(comp => {
      //   comp.collapseIfNotFocus(_orderId);
      // });
      
      this.autoScrollTo(_orderId);
    },
    onOrderCollapsed(_orderId) {
      this.autoScrollTo(_orderId);
    },
    autoScrollTo(_orderId) {
      setTimeout(() => {
        this.$vuetify.goTo(`#order-${_orderId}`);
      }, 250);
    },

    onNewOrderAlertDismissed() {
      SoundManager.get().stop();//FIXME: Stop sound
      this.newOrdersAlert.newOrdersCount = 0;
      this.snackbar.new.show = false;
    },

    /// Setting action methods

    onFilterTypeChanged(_type) {
      this.turnOffSearch();
      this.$store.commit('setFilterType', _type);
    },
    search(_searchString) {
      this.searchString = _searchString;
      this.$store.commit('setSearchString', _searchString);
    },
    turnOffSearch() {
      this.searchString = '';
      this.$store.commit('setSearchString', '');
    },
    signout() {
      // Deactivate reminder loop.
      clearTimeout(this.reminderLoopTimeout);
      clearInterval(this.reminderLoopInterval);

      // Remove touchend/mouseup listener.
      document.removeEventListener('touchend', this.inactivityBreaker, false);
      document.removeEventListener('mouseup', this.inactivityBreaker, false);

      // Unsubscribe to Firestore collection changes.
      FirestoreDataEvent.$emit('unsubscribe-orders');

      // Request sign-out.
      this.$store.dispatch('signout')
      .then(response => {
        console.log(`Sign-out succeeded.`, response.data);
      })
      .catch(err => {
        console.error(`Sign-out failed`, err);
        alert('Sign-out failed.');
      })
      .finally(() => {
        setTimeout(() => {
          this.$router.push('/auth');
        }, 300)
      });
    },


    /// An 1-minute loop to check inactivity and pending orders.
    reminderLoop() {
      this.inactivityTimer++;
      let currentTs = moment().unix();      
      console.log(`[reminder-loop] begins at ${currentTs}. Inactivity count ${this.inactivityTimer}`);

      //1. Check timing of every order.
      if (this.$refs['order-cards']) {
        this.$refs['order-cards'].forEach(oc => {
          oc.checkReminder(currentTs);
        });
      }

      //2. Check inactivitiy.
      if (this.inactivityTimer >= 2) {//NOTE: At most 2-min of inactivity...
        // Check if there're unresolved orders, i.e. status is 'new'
        if (this.hasUnresolvedOrders) {
          this.overlay.inactivity.show = true;
          // Play some alarming sound as well.
          SoundManager.get().play('pending', true);
        }
        else {
          this.inactivityTimer = 0;
        }
      }
    },

    inactivityBreaker() {
      this.inactivityTimer = 0;
      SoundManager.get().stop();//FIXME: A simple touch will stop the sound.
    },
  },

  data() {
    return {
      isSyncDone: false,

      overlay: {
        entry: {
          show: true
        },
        inactivity: {
          show: false
        }
      },
      snackbar: {
        new: {
          show: false,
          message: ''
        }
      },
      newOrdersAlert: {
        on: false,
        newOrdersCount: 0,
      },

      searchString: '',
      filterType: 'pending',
      sortingMode: 'status',

      reminderLoopTimeout: undefined,
      reminderLoopInterval: undefined,
      inactivityTimer: 0
    };
  },

  computed: {
    orders() {
      return this.$store.getters.orders;
    },

    shopId() {
      return this.$store.state.shopId;
    },
    shopName() {
      return this.$store.state.shopName;
    },

    hasUnresolvedOrders() {
      return this.$store.getters.orders.some(o => {
        return o.status === 'new';
      });
    },

    version() {
      return this.$store.state.version;
    },
    themeColor() {
      // return process.env.VUE_APP_PWA_THEME_COLOR;
      return process.env.VUE_APP_THEME_COLOR;
    },
    themeColorLighten() {
      return process.env.VUE_APP_PWA_THEME_COLOR_LIGHTEN;
    }
  },

  watch: {
    filterType(type) {
      if (['all', 'pending', 'done', 'future'].indexOf(type) >= 0) {
        this.searchString = '';
        // this.reminderInterval();//NOTE: This should update the reminder status of every order.
      }
    },

    version(v) {
      console.warn(`Version change detected`, v);
      // if (process.env.PACKAGE_VERSION !== v) {
      //   alert(`有更新的版本可用（${v}），請按「確定」後執行登出，並重新登入以更新。`);
      // }
    }
  },

  filters: {
    toFilterLiteral(s) {
      return {
        'all': '所有',
        'pending': '待處理',
        'done': '已處理',
        'future': '預約（明天以後）'
      }[s];
    },

    padPhoneNumber(s) {
      if (s >= 900000000)
        return `${s}`.padStart(10, '0');
      return `${s}`;
    }
  }
}
</script>

<style scoped>
.mpos {
  padding: 4px 8px 72px 8px !important;
  /* padding-top: 8px; */
  /* background: #F3F3F3; */
}

.v-speed-dial--bottom {
  bottom: 48px !important;
}
</style>

<style>
body {
  background: #EFEFEF;
}
</style>